/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// loads the jquery package from node_modules
import $ from 'jquery';

// app.js
$(function($) {
    console.log('app.js');

    // cookie consent
    let cookie_consent = getCookie('donuva-cookie-consent');
    if (cookie_consent == null || cookie_consent !== '1') {
        $('#cookie-consent-container').fadeIn();
        $('#cookie-consent-container button#accept').on('click', function() {
            $(this).attr('disabled', 'disabled');
            $('#cookie-consent-container').fadeOut();
            setCookie('donuva-cookie-consent', '1', 7);
        });
    }

    // FUNZIONI
    function getCookie(key) {
        return document.cookie
            .split('; ')
            .find((row) => row.startsWith(key+'='))
            ?.split('=')[1];
    }
    function setCookie(key, value, max_age_days=1) {
        let max_age_seconds = max_age_days*24*60*60;
        document.cookie = key + "=" + value +"; max-age=" + max_age_seconds + "; SameSite=Lax";
    }
    /**/
});